/*##########
## Header ##
##########*/
header {
    position: relative;
    z-index: 100;


}

header>div {
    background: rgb(159 159 159 / 41%);
    padding: 0.5rem 0;
    border-radius: 15px;
    margin-top: 1.5rem;
    position: fixed;
    width: 95%;
    left: 50%;
    transform: translate(-50%);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.black-line {
    height: 7px;
    background-color: black;
    border-radius: 25px;
    width: 42%;
    border: 0.1px solid black;

}


#link-logo {
    margin: 0 2rem;
    display: block;
    height: 4rem;
    width: 4rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;

}

#link-logo:hover {

    transition: 0.7s;
    transform: scale(1.25);
    background-color: rgba(159, 159, 159, 0.548);

}

/*#########
## Error ##
#########*/
#container-error {
    text-align: center;
    border: solid 2px red;
    border-radius: 20px;
    padding: 0.5rem;
    margin: 0.5rem;

    font-size: 0.8rem;
}

.page-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-error div {
    font-size: 1rem;
    padding: 1rem;
    text-align: center;
}

.standard-page.error {
    flex-direction: column;
    padding: 30%;
}

/*################
## ProductAdmin ##
################*/
#edit-product {
    display: flex;
    margin: 0 4rem 2rem;
    padding: 2rem;
    flex-direction: column;
    gap: 4rem;
    border: 2px solid black;
    border-radius: 10px;
}

#edit-product label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

}

#edit-product label input,
#edit-product label select,
#edit-product label option,
#edit-product label textarea {
    text-align: center;
    padding: 0.5rem;
}

#edit-product label span {
    width: 8rem;
    text-align: start;
    margin-right: 1rem;
}

/*###########
## Loading ##
###########*/
#loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;

    font-size: 1.5rem;

    min-height: 100vh;

}

#loader.modal-closing-sesion {
    background-image: none;
    min-height: auto;
}

.loading {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}

.loading::before,
.loading::after {
    content: "";
    position: absolute;
    border-radius: inherit;
}

.loading::before {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgb(0, 255, 128) 0%,
            #80f 100%);
}

.loading::after {
    width: 85%;
    height: 85%;

    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-children {
    text-align: center;
}

.loading.bg-orange::after {
    background-color: #f4d4c1;

}


@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/*##########
## Footer ##
##########*/
#footer {
    margin-top: auto;
    min-height: 2rem;
    border: 1px solid black;
}

#footer div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    margin: 1rem;
}

#footer div a {
    color: black
}

/*########
## List ##
########*/

#list {
    height: 3rem;
    width: 3rem;
    background-color: #f7e0d3;
    border-radius: 10px;
    position: fixed;
    right: 2rem;
    bottom: 4rem;
}



#list:hover {
    cursor: pointer;
    height: 3.5rem;
    width: 3.5rem;
    transition: 0.4s;
    transform: scale(.95);
}

#list img {
    width: 100%;
}

#list #quantity {
    font-size: 13px;
    color: white;
    background: #262626;
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-radius: 50%;
    display: grid;
    place-items: center;
    top: 2.3rem;
    right: 2.3rem;

}

#container-modal-list {
    z-index: 100;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: RGB(0, 0, 0, 43%);
}

#modal-list {
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 100vh;
    padding: 2rem;

    overflow-y: scroll;

}

#modal-list #header-list {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    margin-bottom: 2rem;
}

#modal-list #header-list .empty-list {
    background: #262626;
    color: white;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 10px;
    transition: all .3s ease;
}

#modal-list #header-list .empty-list:hover {
    background: #2626269d;
    transform: scale(1.03);
    border-radius: 0;
}


#modal-list #x-button {
    text-align: end;
}


#modal-list span {
    border: 1px solid black;
    border-radius: 20px;
    padding: 0.3rem;
    transition-property: #FFE922, black, bottom;
    transition-duration: .5s;
}

#modal-list span:hover {
    background-color: #FFE922;
    cursor: pointer;

}

#modal-list li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

#modal-list li>div {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modal-list li img {
    width: 103px;
    height: 102px;
    border-radius: 54px;
}

@media (min-width: 760px) {

    /*########
    ## List ##
    ########*/
    #modal-list {
        width: 50%;

    }

    #modal-list li {
        flex-direction: row;
    }
}

@media (min-width: 1000px) {

    /*########
    ## List ##
    ########*/
    #modal-list {
        width: 40%;

    }

}

@media (min-width: 1400px) {

    /*########
    ## List ##
    ########*/
    #modal-list {
        width: 25%;

    }

}