/*#############
## General ##
##############*/
* {
    font-family: 'Comfortaa', sans-serif;
    font-weight: bold;
}

.standard-page {
    padding-top: 12rem;
    min-height: 100vh;
    display: flex;
}

.bye {
    display: none;
}




/*#############
## Home-Page ##
##############*/
#home-page {
    display: grid;
    gap: 0.5rem;
}

#home-page a {
    text-decoration: none;
}

#home-page h2 {
    color: white;
    font-size: 40px;
    text-align: center;
}

#home-page .img-home-page {
    border: 1px solid black;
    height: 70vh;
    display: grid;
    place-items: center;


}

#home-page .img-home-page:hover {
    cursor: pointer;
    transition: 1s;
    transform: scale(.95);


}

#home-page .img-home-page {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


/*#############
## Login-Page ##
##############*/

#login-page {
    min-height: 100vh;
    display: grid;
    place-items: center;
}


#login-form {
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 2rem;
    background: rgb(159 159 159 / 41%);
    width: 263px;
}

#login-form label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

#login-form label input {
    padding: 0.5rem;
    border-radius: 20px;
}



/*#######################
## Product && Products ##
########################*/
.showw0 img {
    opacity: 0.4;
}

#icon-show-product {
    height: 2rem;
    width: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    margin-left: 14rem;
    margin-bottom: 27rem;

}

#icon-show-product:hover {
    cursor: pointer;
    background-color: rgba(70, 70, 70, 0.575);
    transition: 0.5s;
    border-radius: 25px;
}

.img-product {
    width: 274px;
    height: 258px;
    border-radius: 54px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.name-product {
    font-size: 32px;
    text-align: center;
    padding: 0.5rem;
}

.yellow-button {
    text-decoration: none;
    color: black;
    background-color: #FFE922;
    width: 169px;
    height: 29px;
    border-radius: 25px;
    display: grid;
    place-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

}

.yellow-button:hover {
    transition: 1s;
    translate: 0.5rem -0.5rem;
    background-color: #b9aa1c;
    cursor: pointer;
}

#products-list {
    display: flex;

    justify-content: center;
    flex-wrap: wrap;
}

#products-list li {
    list-style: none;
    width: min-content;
    margin: 0 10rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#products-list div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

#individual-product figure {
    display: grid;
    text-align: center;
    place-items: center;
    gap: 1rem;
    padding-bottom: 4rem;
    position: relative;


}

#product-content {
    width: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

}

#product-content span {
    position: absolute;
    bottom: 2rem;
}


#product-info h3 {
    text-align: center;
    font-size: 32px;
}

#product-info figcaption {
    font-size: 29px;
    max-width: 760px;
    padding: 2rem;

}

#product-info #product-price {
    margin-top: 2rem;
    font-size: 25px;


}

#product-info #product-price span {
    font-size: 18px;
    display: block;
    margin-top: 0.5rem;
}

#back-plus img {
    background-color: #f7e0d3;
    border-radius: 10px;
    padding: 0.5rem;
    width: 3rem;
    position: fixed;
    bottom: 9rem;
    right: 2rem;
}

#back-arrow img {
    width: 3rem;
    position: absolute;
    top: 8rem;
    left: 2rem;
}

/*################
## Haz tu Pedido ##
#################*/
#haz-tu-pedido {
    padding: 8rem 1rem;
    display: flex;
    flex-direction: column;
}

#haz-tu-pedido a {
    color: black;
    text-decoration: none;
}

#haz-tu-pedido h2 {
    font-size: 32px;
    margin: 2rem 0;
    text-align: center;
}

#haz-tu-pedido #haz-pedido-row2 {
    display: grid;
    margin: auto;
    place-items: center;
    grid-template-areas:
        'llama'
        'telf1'
        'telf2'
    ;
}

#haz-tu-pedido .haz-tu-pedido-telefono {
    width: 270px;
    height: 133px;
    transition: .3s;
}

#haz-tu-pedido .haz-tu-pedido-telefono:hover {
    padding: 1rem;
    border: 2px solid black;
    border-radius: 10px;
}

#haz-tu-pedido .haz-tu-pedido-telefono figure {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

#haz-tu-pedido .haz-tu-pedido-telefono:nth-child(1) {
    grid-area: telf1;
}

#haz-tu-pedido .haz-tu-pedido-telefono:nth-child(2) {
    grid-area: llama;
}

#haz-tu-pedido .haz-tu-pedido-telefono:nth-child(3) {
    grid-area: telf2;
}

#haz-tu-pedido .icon-haz-pedido {
    object-fit: contain;
}

#haz-tu-pedido .telefono {
    width: 76px;
    height: 60px;
    font-size: 20px;
}

#haz-tu-pedido .llamanos {
    width: 137px;
    height: 80px;
}

#haz-tu-pedido .shop {
    width: 125px;
    height: 80px;
}

#haz-tu-pedido .repartidor {
    width: 117px;
    height: 80px;
}


#haz-tu-pedido .haz-pedido-background {
    background-color: #E8E1BF;
    border-radius: 10px;
    width: 338px;
    height: 150px;
    font-size: 32px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

#haz-tu-pedido>div {
    text-align: center;
}


#haz-tu-pedido .haz-pedido-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

#haz-tu-pedido #haz-pedido-row4 {
    display: grid;
    margin: auto;
    grid-template-areas:
        'casa'
        'o'
        'tienda'
    ;
}

#haz-tu-pedido #haz-pedido-row4 .haz-tu-pedido-columns:first-child {
    grid-area: tienda;
}

#haz-tu-pedido #haz-pedido-row4 .haz-tu-pedido-columns:last-child {
    grid-area: casa;
}

#haz-tu-pedido #haz-pedido-row4 {
    height: max-content;
    justify-content: space-evenly;
    align-items: start;
}

.haz-tu-pedido-columns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.haz-tu-pedido-columns .extra-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.haz-tu-pedido-columns .extra-info figure {
    width: 356px;
    height: 97px;
    font-size: 20px;

}

.haz-tu-pedido-columns .extra-info figure img {
    height: 81px;
    width: 96px;
}


/*################
## Quines somos ##
#################*/
#quienes-somos {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}

#quienes-somos a {
    color: black;
    text-decoration: none;
}

#content-quienes-somos {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 2rem; */
    gap: 3rem;
    font-size: 32px;
    text-align: center;
}

#quienes-somos #content-quienes-somos figcaption {
    margin: 1rem 0;
}


#quienes-somos .fruit-store {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
}



#quienes-somos .fruit-store img {
    width: 338px;
    height: 212px;
    object-fit: cover;
}

#quienes-somos .fruit-store .telf-ubi {
    margin-bottom: 2rem;
}

#quienes-somos .fruit-store .telf-ubi figure {
    font-size: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1rem;

}


#quienes-somos .fruit-store .telf-ubi img {
    object-fit: contain;
    width: 86px;
    height: 57px;
}

#quienes-somos .fruit-store .telf-ubi figure:nth-child(2) img {
    width: 76px;
    height: 51px;
}

#quienes-somos .separator {
    height: 7px;
    background-color: black;
    border-radius: 25px;
    width: 80%;
    border: 0.1px solid black;
}

#quienes-somos .separator.desktop {
    display: none;
}

#quienes-somos #map {
    display: none;
}

#quienes-somos .special-p {
    margin: 5rem 0 10rem;
}

#quienes-somos #timetable {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#quienes-somos #timetable>div {
    margin: 1rem;
    font-size: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
}

#quienes-somos #timetable>div p {
    margin: 1rem 0;
}


#quienes-somos #timetable>div span {
    padding-left: 1rem;
    display: none;
}


/*###############
## Aviso legal ##
###############*/
.aviso-politicas {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.aviso-politicas div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 70%;

}

.aviso-politicas h3 {
    font-size: 30px;
}

.aviso-politicas h4 {
    font-style: oblique;
    font-size: 25px;
    margin-bottom: 0.5rem;
}

.aviso-politicas ul {
    padding-left: 2rem;
    margin: 1rem 0;
}


/*###############
## New-Product ##
################*/
#new-product {
    flex-direction: column;
}

#new-product #edit-product input {
    width: 11rem;
}




@media (min-width: 1000px) {

    /*#############
    ## Home Page ##
    #############*/
    #home-page {
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
    }

    #home-page .img-home-page {
        border: 1px solid black;
        height: 100vh;
        width: 100%;
    }

    /*###########
    ## Product ##
    ###########*/
    #individual-product figure {
        gap: 2rem;
        grid-template-columns: 1fr 2fr;
        margin: 0 8rem;
        text-align: left;
    }

    #product-content span {
        position: unset;

    }

    #product-info h3 {
        text-align: left;
        font-size: 40px;
    }

    #product-info figcaption {
        font-size: 32px;

    }

    #product-info #product-price {
        font-size: 29px;


    }

    #product-info #product-price span {
        font-size: 20px;

    }

    /*################
    ## Haz tu Pedido ##
    #################*/

    #haz-tu-pedido .haz-pedido-background {
        height: 229px;
        flex-direction: column;
    }


    #haz-tu-pedido #haz-pedido-row2 {
        display: grid;
        grid-template-areas:
            'telf1 llama telf2'
        ;
    }

    #haz-tu-pedido #haz-pedido-row4 {
        grid-template-areas:
            'tienda o casa'
        ;
    }

    #haz-tu-pedido #haz-pedido-row4 h2 {
        margin: 6rem 4rem;
        grid-area: o;
    }


    /*#################
    ## Quienes somos ##
    ##################*/
    #quienes-somos .fruit-store>figure img {
        width: 603px;
        height: 349px;
        object-fit: cover;
    }

    #quienes-somos .fruit-store .telf-ubi {
        display: none;
    }

    #quienes-somos .separator.movile {
        display: none;
    }

    #quienes-somos .separator.desktop {
        display: inline-block;
    }

    #quienes-somos #timetable {
        text-align: left;
    }

    #quienes-somos #map {
        display: inline-block;
    }

    #quienes-somos #timetable>div span {
        display: inline-block;
    }
}